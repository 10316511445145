import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

export const BackgroundImg = ({image, className = "", id = "", style = {}, children}) => {

  const gatsbyImage = getImage(image);
  const bgImage = convertToBgImage(gatsbyImage);
  
  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
      className={className}
      id={id}
      style={style}
    >
      {children}
    </BackgroundImage>
  )
}

export default BackgroundImg
